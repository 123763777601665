<template>
  <v-btn icon @click="callback">
    <v-icon
      v-if="settings.graphics && settings.graphics.mode == 'low'"
      class="align-center"
      style="font-size: 2rem"
      :color="color"
    >
      {{
        icon == "ArrowButtonPre"
          ? "mdi-arrow-left-circle"
          : "mdi-arrow-right-circle"
      }}</v-icon
    >
    <v-img
      v-else
      contain
      max-width="40"
      class="mx-auto"
      :src="require('@/assets/island/' + icon + '.png')"
    ></v-img>
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  props: ["callback", "icon", "color"],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>